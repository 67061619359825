label {
    @apply
    text-sm
    text-[#2c2c2c];
    font-family: "Euclid Circular A";
}

.select_variant_1, .input_variant_1 {
  @apply h-[55px] 
    font-normal
    text-[12px]
    lg:text-sm 
    text-[#6c6c6c] 
    border 
    border-[#979797]
    rounded-[10px]
    p-[10px]
    m-0;
  font-family: "Euclid Circular A";
}

.text-area {
    @apply 
      font-normal
      text-[12px]
      lg:text-sm 
      text-[#6c6c6c] 
      border 
      border-[#979797]
      rounded-[10px]
      m-0;
    font-family: "Euclid Circular A";
  }

.input_container {
    @apply flex flex-col space-y-1;
}

.input_container_grid {
    @apply grid grid-cols-1 md:grid-cols-2 gap-10;
}

.form_header-container {
    @apply h-[100px]
    w-auto
    flex
    items-center
    self-center
    border 
    border-[#80808040]
    py-[10px] px-5
    rounded-xl
    mb-[35px];
}

hr {
    @apply h-[1px]
    bg-[#80808040];
}

.form_header {
    @apply text-center text-lg font-semibold leading-6 lg:text-2xl;
    font-family: "Euclid Circular A";
}

.font-euclid {
    font-family: "Euclid Circular A";
}

.input_variant_2 {
  @apply 
    w-full 
    h-[45px] 
    text-[#666] 
    bg-white 
    p-[11px] 
    m-0 
    text-sm 
    font-light 
    rounded-[5px] 
    outline-none
    border 
    placeholder-gray-300
    border-[#80808040];

  font-family: "Euclid Circular A";
}

.button_variation_1 {
  @apply
    w-full
    py-[10px]
    px-0
    my-[10px]
    mx-auto
    rounded-[5px]
    border-none
    bg-[#3e5ea9]
    text-sm
    font-medium
    text-white;
}
