* {
     margin: 0;
     padding: 0;
}

.infoText{
     font-size: 10px !important;
     color: #929292 !important;
 }
@import url("https://fonts.cdnfonts.com/css/euclid-circular-a?styles=100037,100032,100034,100033,100036,100035,100039,100038,100031,100030");

body > * {
     font-family: "Euclid Circular A",
}

.font-euclid {
     font-family: "Euclid Circular A",
}

 select {
     width: 100% !important;
     height: 45px;
     padding-left: 11px;
     border-radius: 5px;
     border: 1px solid rgba(128, 128, 128, 0.25);
     background: #fff;
     margin-left: 0%;
     font-size: 12px;
     color: #6c6c6c;
     font-weight: 300;
 }

 .heala-root-form-container textarea {
     width: 100% !important;
     /* padding-left: 11px; */
     padding-top: 11px;
     border-radius: 5px;
     border: solid 1px #cbc9c9;
     background: #fff;
     margin-left: 0%;
     margin-bottom: 10px;
     font-size: 13px;
     height: 80px;
 }


 .heala-root-form-container button {
     width: 100% !important;
     padding: 10px 0;
     margin: 10px auto;
     border: none;
     font-size: 14px;
     font-weight: 500;
     color: #fff;
 }
.heala-root-form-container {
     position: relative;
     height: 100vh;
     display: flex;
     align-items: center;
     justify-content: center;

     width: 95%;

     margin: 0 auto;
}

.heala-root-form-container div,
.heala-root-form-container h3,
.heala-root-form-container form,
.heala-root-form-container input,
.heala-root-form-container p {
     padding: 0;
     margin: 0;
     outline: none;
     font-family: "Euclid Circular A";
     font-size: 16px;
     color: #666;
}

.heala-root-form-container h3 {
     font-family: "Euclid Circular A";
     font-style: normal;
     font-weight: 400;
     font-size: 24px;
     line-height: 30px;
     display: flex;
     align-items: center;
     color: #2c2c2c !important;
     width: 100%;
     justify-content: center;
     margin: 16px 0;
}

.heala-root-form-container p {
     font-size: 12px;
}

.heala-root-form-container hr {
     color: #a9a9a9;
     opacity: 0.3;
}

.heala-root-form-container .main-block {
     padding: 24px;
     margin: auto;
     background: #fbfbfb;
     border: 1px solid rgba(128, 128, 128, 0.25);
     border-radius: 16px;
     height: auto;
     overflow: hidden;
     max-height: calc(100vh - 80px);
}

.heala-root-form-container .account-type,
.heala-root-form-container .gender {
     margin: 15px 0;
}

.heala-root-form-container label#icon {
     margin: 0;
     border-radius: 5px 0 0 5px;
}

.heala-root-form-container label.radio {
     position: relative;
     display: inline-block;
     padding-top: 4px;
     margin-right: 20px;
     text-indent: 30px;
     overflow: visible;
     cursor: pointer;
}


.heala-root-form-container label.radio:before {
     content: "";
     position: absolute;
     top: 2px;
     left: 0;
     width: 20px;
     height: 20px;
     border-radius: 50%;
     background: #3e5ea9;
}

.heala-root-form-container label.radio:after {
     content: "";
     position: absolute;
     width: 9px;
     height: 4px;
     top: 8px;
     left: 4px;
     border: 3px solid #fff;
     border-top: none;
     border-right: none;
     transform: rotate(-45deg);
     opacity: 0;
}

.heala-root-form-container input[type="radio"]:checked+label:after {
     opacity: 1;
}

.heala-root-form-container input[type="email"],
.heala-root-form-container input[type="tel"],
.heala-root-form-container input[type="text"] {
     width: 100%;
     height: 45px;
     padding-left: 11px;
     border-radius: 5px;
     border: 1px solid rgba(128, 128, 128, 0.25);
     background: #fff;
     font-size: 12px;
     color: #6c6c6c;
     font-weight: 300;
}

select {
     width: 100%;
     height: 45px;
     padding-left: 11px;
     border-radius: 5px;
     border: 1px solid rgba(128, 128, 128, 0.25);
     background: #fff;
     margin-left: 0%;
     font-size: 12px;
     color: #6c6c6c;
     font-weight: 300;
}

.heala-root-form-container textarea {
     width: 100%;
     /* padding-left: 11px; */
     padding-top: 11px;
     border-radius: 5px;
     border: solid 1px #cbc9c9;
     /* box-shadow: 1px 2px 5px rgb(0 0 0 / 9%); */
     background: #fff;
     margin-left: 0%;
     margin-bottom: 10px;
     font-size: 12px;
     height: 80px;
     font-family: "Euclid Circular A";
     padding: 2%;

}

.heala-root-form-container #icon {
     display: inline-block;
     padding: 9.3px 15px;
     box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.09);
     background: #3e5ea9;
     color: #fff;
     text-align: center;
}

.heala-root-form-container .btn-block {
     margin-top: 30px;
     text-align: center;
}

.heala-root-form-container button {
     width: 100%;
     padding: 10px 0;
     margin: 10px auto;
     border-radius: 5px;
     border: none;
     background: #3e5ea9;
     font-size: 14px;
     font-weight: 500;
     color: #fff;
}

.heala-root-form-container .iframe-close {
     border-radius: 50%;
     position: absolute;
     top: 0;
     right: 0;
     /* padding: 1.4% 2.1% 2%; */
     cursor: pointer;
     color: white;
     z-index: 200;
     background: #3d5da9;
     width: 35px;
     height: 35px;
     margin: 2%;
     display: flex;
     justify-content: center;
     align-items: center;
     
}

.heala-root-form-container .iframe-close1 {
     border-radius: 50%;
     position: absolute;
     top: 0;
     right: 0;
     /* padding: 1.4% 2.1% 2%; */
     cursor: pointer;
     color: white;
     z-index: 200;
     width: 35px;
     height: 35px;
     margin: 2%;
     display: flex;
     justify-content: center;
     align-items: center;
}


.heala-root-form-container button:hover {
     background: #3e5ea9;
}

.heala-root-form-container .heala-login-tab {
     width: 340px;
     padding: 10px;
     margin: auto;
     border-radius: 5px;
     border: solid 1px #ccc;
     box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.31);
     background: #ebebeb;
     position: absolute;
     bottom: 100%;
     right: 100%;
     margin-right: 16px;
     margin-bottom: 16px;
     display: none;
     background: #fbfbfb;
     border: 1.11698px solid #eaeaea;
     border-radius: 9.57px;
}
.heela-form-title-logo {
     max-width: 150px;
     max-height: 60px;
}

.heela-form-title-container {
     display: flex;
     align-items: center;
     justify-content: center;
     gap: 4px;
     padding: 16px 0px !important;
     border-bottom: 1px solid #979797;
}
.heela-form-description {
     font-family: "Euclid Circular A";
     font-style: normal;
     font-weight: 400;
     font-size: 12px ;
     line-height: 20px;
     display: flex;
     align-items: center;
     text-align: center;
     color: #6c6c6c ;
     justify-content: center;
}

.heela-form-controller {
     overflow-y: scroll;
     overflow-x: hidden;
     /* IE and Edge */
     scrollbar-width: none;
     max-height: calc(100vh - 280px);
}

.heela-form-controller h3 {
     font-family: "Euclid Circular A";
     font-style: normal;
     font-weight: 400;
     font-size: 24px;
     line-height: 30px;
     display: flex;
     align-items: center;
     color: #2c2c2c !important;
     width: 100%;
     justify-content: center;
     margin: 16px 0;
}

.heela-form-controller::-webkit-scrollbar {
     display: none;
}

.heela-form-symptoms {
     flex-direction: column;
     display: flex;
     align-items: center;
     justify-content: center;
     gap: 8px;
}

.heela-form-symptoms .add-icon {
     height: 100%;
     margin-top: 10px;
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;
}
.heela-form-top {
     display: flex;
     width: 100%;
}

.heela-symptoms-input {
     width: 100%;
     display: flex;
}

.heela-symptoms-container {
     background-color: #ccc;
     padding: 1rem;
     display: flex;
     flex-direction: column;
     gap: 10px;
     cursor: pointer;
     border-radius: 5px;
     align-self: flex-start;
}

.heela-symptoms-option {
     transition: all .2s ease;
}

.heela-symptoms-option:hover {
     color: #fbfbfb;
}

.heela-symptoms-batches-container {
     display: flex;
     justify-content: flex-start;
     align-items: center;
     margin-top: 4px !important;
     gap: 4px;
     flex-wrap: wrap;
}

.heela-batches-cancel {
     background-color: #9e9999;
     padding: 4px;
     cursor: pointer;
     height: 20px;
     display: flex;
     justify-content: center;
     align-items: center;
}

.red{
     display: flex;
    justify-content: center;
    align-self: center;
    color: red;
    font-size: 1.2rem;

}

.redtext{
     display: flex;
     justify-content: left;
     align-self: flex-start;
     margin:  0%;
     color: red;
     font-size: 0.7rem;
     font-style: italic;
 }
.green{
     display: flex;
    justify-content: center;
    align-self: center;
    color: green;
    font-size: 1.2rem;

}

.gray{
     display: flex;
    justify-content: center;
    align-self: center;
    color: gray;
    font-size: 1.2rem;
}

.makeStyles-logo-3{
     max-height: "50px" !important;
     max-width: "150px" !important;
 }
 .makeStyles-powered-11{
     position: absolute !important;
     top: 93% !important;
 }
 @media (max-width: 599.95px){
.makeStyles-br-40 {
     display: block !important;
}
}
@media screen and (max-width: 600px) {
     .makeStyles-br-10 {
          display: block !important;
      }
     .makeStyles-powered-11{
          position: absolute !important;
          top: 90% !important;
      }
     .makeStyles-button1-24 {
          width: auto;
          height: auto;
          padding: 9px 60px !important;
          font-size: 18px !important;
          align-self: center;
          background: #3E5EA9 !important;
          margin-top: 55px !important;
          font-family: Euclid Circular A !important;
          font-weight: 500 !important;
          border-radius: 12px !important;
          text-transform: capitalize !important;
      }
     .redtext{
          display: flex;
          justify-content: left;
          align-self: flex-start;
          margin: 2% 0%;
          color: red;
          font-size: 0.6rem;
          font-style: italic;
      }
     .conBG {
          padding: 8px !important;
          border-radius: 20px !important;
     }

     .heala-root-form-container .main-block {
          width: auto;
          padding: 24px;
          margin: auto;
          background: #fbfbfb;
          border: 1px solid rgba(128, 128, 128, 0.25);
          border-radius: 16px;
          height: auto;
          overflow: hidden;
          max-height: calc(100vh - 80px);
     }

     .heela-form-container {
          width: 100vw;
          /* height: 100vh; */
          border-radius: 0px;
          padding: 20px !important;
          height: calc(100% - 40px);
          padding-bottom: 35px !important;
          padding-top: 40px !important;
     }

     .heela-form-controller {
          max-height: calc(100vh - 180px);
     }

}

@media screen and (max-width: 900px) {
     .makeStyles-content-36 {
          width: 95%;
          margin: 20px 0px;
          align-items: flex-start;
          display: flex;
          align-self: center;
          justify-content: center;
      }
     .makeStyles-content-13 {
          width: 95%;
          margin: 20px 0px;
          display: flex !important;
          justify-content: center !important;
          
          align-self: center !important;
      }
     .makeStyles-footer-22 {
          padding: 28px 25px !important;
      }
     .makeStyles-foot-112 {
          padding: 10px 30px;
      }
      .makeStyles-button-23 {
          width: auto;
          height: auto;
          margin-top: 25px !important;
      }
}

select{
     background-image:url('https://cdn-icons-png.flaticon.com/512/152/152415.png');
     background-position : calc(100% - 10px) center;
     background-size:10px;
       background-repeat :no-repeat;
       
      
     -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
     padding-right: 25px;
}

