@tailwind base;
@tailwind components;
@tailwind utilities;

* {
     box-sizing: border-box;
}

body {
     margin: 0;
     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
          "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     width: 100%;
     padding: 0;

}

code {
     font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.heala-root-form-container input[type="email"],
.heala-root-form-container input[type="tel"],
.heala-root-form-container input[type="text"],
.heala-root-form-container select,
.heala-root-form-container textarea {
     @apply focus:outline focus:outline-2;
}

.partner-logo {
     @apply w-[200px] h-auto max-h-[64px] object-contain;
}

.form-container {
     @apply space-y-3 !mt-4 !mb-4 !px-2;
}
